import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Head } from 'components';
import Img from 'gatsby-image';
import TeamMembers from '../components/sections/TeamMembers';
import { H2, P } from 'style';
import {
  backgroundGray,
  desktopWidth,
  fontSizeMd,
  fontSizeLg,
  labelGray,
  mobileHdWidth,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperXl,
  paddingWrapperXxl,
  paddingLg,
  tabletWidth,
  white,
} from 'style/constants';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${paddingWrapperXxl} ${paddingWrapperMd};
  background-color: ${props => (props.dark ? backgroundGray : white)};

  @media (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperXl};
    padding-bottom: ${paddingWrapperXl};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 850px;
`;

const MainImage = styled.div`
position: relative;
overflow: hidden;
max-height: 450px;
min-width: 100%;
margin-bottom: 30px;
@media (max-width: ${tabletWidth}) {
  max-height: 350px;
}
@media (max-width: ${mobileHdWidth}) {
  max-height: 200px;
}
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    text-align: center;
  }

  p {
    max-width: 650px;
    text-align: center;
    color: ${labelGray};
    font-size: ${fontSizeLg};
    padding-top: ${paddingLg};

    @media (max-width: ${tabletWidth}) {
      font-size: ${fontSizeMd};
    }
  }
`;

/* #region  Blog Query */
export const query = graphql`
  query staffQuery {
    allPrismicAboutHome {
      edges {
        node {
          data {
            main_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            main_title {
              html
              text
            }
          }
        }
      }
    }
    allPrismicTeamMember(sort: {order: ASC, fields: prismicId}) {
      edges {
        node {
          data {
            bio {
              html
              text
            }
            name {
              html
              text
            }
            profile_photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 350, maxHeight: 350, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                  fixed {
                    src
                  }
                }
              }
              alt
            }
            role {
              html
              text
            }
          }
        }
      }
    }
  }
`;
/* #endregion */

export default ({ data }) => {
  const doc = data.allPrismicAboutHome.edges.slice(0, 1).pop();
  const teamMembers = data.allPrismicTeamMember.edges;
  if (!doc) return null;
  return (
    <React.Fragment>
      <Head title="About" />
      <Container>
        <Wrapper>
          <Header>
            <H2>{doc.node.data.main_title.text}</H2>
            <MainImage>
              <Img fluid={doc.node.data.main_image.localFile.childImageSharp.fluid}
              />
            </MainImage>
          </Header>
          <TeamMembers teamMembers={teamMembers} />
        </Wrapper>
      </Container>
    </React.Fragment>
  );
};
