import React from 'react';
import styled from 'styled-components';
import { Typography, H5, P } from 'style';
import renderHTML from 'react-render-html';
import {
  backgroundGray,
  mobileHdWidth,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingWrapperXl,
  paddingWrapperXxl,
  paddingLg,
  paddingMd,
  tabletWidth,
  mobileWidth,
  paddingXxl,
} from 'style/constants';

const TeamMemberInfo = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 350px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: ${paddingLg};
  min-width: 350px;
`;
const ProfilePhoto = styled.div`
  position: static;
  overflow: hidden;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  width: 100px;
  height: 100px;
`;

const TextInfo = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
`;
const Wrapper = styled.div`
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: ${paddingWrapperLg};
  grid-column-gap: ${paddingWrapperLg};
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;

  @media (max-width: ${tabletWidth}) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  h5 {
    padding-top: ${paddingLg};
    padding-bottom: ${paddingMd};
  }

  p {
    max-width: 700px;
    line-height: 1.5;
  }

  .chip {
    margin-top: ${paddingLg};
  }
`;

const TeamContainer = styled.div`
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  margin-bottom: -${paddingWrapperMd};
`;

export default ({ teamMembers }) => {
  if (!teamMembers) return null;
  return (
    <TeamContainer>
      <Wrapper>
        {teamMembers.map(({ node: teamMember }, i) => (
          <div key={i}>
            <TextInfo>
              <H5>{teamMember.data.name.text}</H5>
              {teamMember.data.role.text && <P>{teamMember.data.role.text}</P>}
            </TextInfo>
            <Typography>{renderHTML(teamMember.data.bio.html)}</Typography>
          </div>
        ))}
      </Wrapper>
    </TeamContainer>
  );
};
